<template>
  <div class="mainContainer container" style="">


    <div style="display:flex;">
      <back-button />
      <h5 class="pageTitle">Test</h5>
    </div>
    <hr>

    <div class="contentBody">


    </div>
  </div>
</template>

<script>
import { datetime, RRule, RRuleSet, rrulestr } from 'rrule'



export default {
  name: 'Test',
  components: {

  },
  data(){
    return{
      faqs:[],
    }
  },
  methods:{

  },
  mounted() {
    var d = new Date();
    console.log(d);

    console.log(d.toLocaleDateString('en-CA'));

    d = new Date();
    d.setHours(0,0,0,0);
    console.log(d);

    d = new Date('2024-01-23');
    console.log(d);
    console.log(d.toLocaleDateString('en-CA'));
    console.log(d.toLocaleDateString('en-CA', {timeZone: "UTC"}));
    d.setHours(0,0,0,0);
    console.log(d.toLocaleDateString('en-CA', {timeZone: "UTC"}));
    console.log(d);

    d = new Date('2024-01-01');
    console.log(d);

    var year;
    var month;
    var day;

    year = d.getFullYear();
    month = `0${d.getMonth() + 1}`.slice(-2);
    day = `0${d.getDate()}`.slice(-2);
    console.log(`${year}-${month}-${day}`);

    d.setMinutes(d.getMinutes()+300);
    year = d.getFullYear();
    month = `0${d.getMonth() + 1}`.slice(-2);
    day = `0${d.getDate()}`.slice(-2);
    console.log(`${year}-${month}-${day}`);

    d = new Date();

    console.log(d.toLocaleDateString('en-CA', {timeZone: "America/New_York"}));
    console.log(d.toLocaleDateString('en-CA', {timeZone: "Asia/Shanghai"}));
    console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)

    var offset = d.getTimezoneOffset();
    console.log(offset);
    console.log(d);
    d.setMinutes(d.getMinutes() + offset);
    console.log(d);

    var shanghaiTime = d.toLocaleDateString('en-CA', {timeZone: "Asia/Shanghai"});
    d = new Date(shanghaiTime);
    console.log(d);


    var newYorkDateString = d.toLocaleDateString('en-CA', {timeZone: "America/New_York"});
    d = new Date(newYorkDateString);
    console.log(d);


    var tzName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log(tzName);
    var tz = d.toLocaleDateString('en-CA', {timeZone: tzName});
    d = new Date(tz);
    console.log(d);


    d = new Date();
    console.log(Date.parse(d));


  }
}
</script>

<style scoped>
.contentBody{
  margin:10px;
  padding:10px;
  font-size:12px;
}
.section{
  margin-bottom:50px;
}
.faq{
  margin-bottom:10px;
}
.link{
  color:#0dcaf0;
  text-decoration: underline;
}


</style>
